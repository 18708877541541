.form-error {
  color: #f44336;
}

.MuiTableFooter-root {
  border-top: 1px solid rgba(224, 224, 224, 1);
}

.MuiTableFooter-root p {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.quote-item-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.quote-item-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.quote-item-estimates {
  /* max-width: 15%; */
  display: block;
  justify-content: center;
  margin-top: 10px;
  margin-right: 10px;
}

.quote-item-inputs-container {
  margin-top: 9px;
  display: flex;
  justify-content: space-between;
  width: 300px;
  max-width: 50%;
  min-width: 300px;
}

.quote-item-estimate:not(:empty) ~ .quote-item-estimate:not(:empty):before {
  content: ", ";
}

.quote-item-price {
  width: 90px;
}

.quote-item-status {
  width: 130px;
  margin-right: 5px;
}

.item-title.invoice-item-title,
.item-title.quote-item-title {
  display: flex;
  align-items: center;
  height: 40px;
  margin-top: 9px;
  padding-right: 10px;
}

.item-title.invoice-item-title > span,
.item-title.quote-item-title > span {
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  align-items: center;
  overflow: hidden;
}

.item-title.invoice-item-title {
  width: calc(100% - 130px);
}

.invoice-item-actions {
  margin-top: 9px;
}

.invoice-items-container .quote-item-row:first-child > .item-title,
.invoice-items-container .quote-item-row:first-child > .invoice-item-actions {
  margin-top: 0;
}

.main-dashboard .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  color: rgba(0, 0, 0, 0.54);
}

@media (min-width: 990px) {
  .quote-item-title:hover {
    cursor: pointer;
  }
}

@media (max-width: 630px) {
  .quote-item-price {
    width: 80px;
  }

  .quote-item-status {
    width: 90px;
  }
}
